//!JULIO: Don't delete this code, it's useful for future implementations
// export function toggleNavBar() {
//   const deviceType = getComputedStyle(document.body, "::before").content.replace(/"/g, "");

//   const navBar = document.getElementById("navbar");
//   const button = document.getElementById("open-nav-menu-btn");

//   const isMobileDevice = deviceType === "mobile";
//   if(!isMobileDevice) return;

//   button.addEventListener("click", () => {
//     navBar.classList.toggle("open");

//     (navBar.classList.contains("open")) 
//       ? document.documentElement.style.overflow = 'hidden'
//       : document.documentElement.style.overflow = 'auto';
//   });
// }

//! JULIO: I would like to implement this logic with only css in the future
export function toggleNavBar() {
  const deviceType = getComputedStyle(document.body, "::before").content.replace(/"/g, "");
  
  const navBar = document.getElementById("navbar");
  const button = document.getElementById("open-nav-menu-btn");
  const navBarContainer = document.getElementsByClassName("navbar-container")[0];
  const mainContent = document.getElementsByClassName("navbar-container__main")[0];
  const postContent = document.getElementsByClassName("navbar-container__post")[0];
  
  button.addEventListener("click", () => {
    const isOpen = navBar.classList.contains("open");
  
    if(!isOpen) {
      navBar.classList.add("open");
      mainContent.style.display = 'flex';
      postContent.style.display = 'flex';
      setTimeout(() => {
        document.documentElement.style.overflow = 'hidden'
        navBarContainer.style.overflowY = 'auto';
      }, 300);
    } else {
      document.documentElement.style.overflow = 'auto';
      navBarContainer.style.overflowY = 'hidden';
      navBar.classList.remove("open");
      setTimeout(() => {
        mainContent.style.display = 'none';
        postContent.style.display = 'none';
      }, 300);
    }
  });
}
module.exports = {
  '/': '/',
  '/servicios/expert-guidance/': '/servicios-estrategia-analitica/',
  '/servicios/data-preparation/': '/servicios-analitica-datos/',
  '/servicios/data-architecture/': '/servicios-arquitectura-datos/',
  '/servicios/business-intelligence/': '/servicios-visualizacion-datos/',
  '/servicios/artificial-intelligence/': '/consultoria-inteligencia-artificial/',
  '/plataforma/intelek-landing/': '/plataforma-analitica-datos/',
  '/plataforma/smart-bi/': '/software-business-intelligence/',
  '/plataforma/features-analytics/': '/software-business-intelligence-funcionalidades/',
  '/plataforma/pricing-analytics/': '/software-business-intelligence-pricing/',
  '/plataforma/data-manager/': '/software-gestion-datos/',
  '/plataforma/governance/': '/plataforma-governanza-datos/',
  '/plataforma/collector/': '/sistema-captura-datos/',
  '/plataforma/features-collector/': '/sistema-captura-datos-funcionalidades/',
  '/plataforma/pricing-collector/': '/sistema-captura-datos-precios/',
  '/plataforma/compute/': '/software-procesamiento-datos/',
  '/plataforma/features-compute/': '/software-procesamiento-datos-funcionalidades/',
  '/plataforma/pricing-compute/': '/software-procesamiento-datos-precios/',
  '/plataforma/data-api/': '/plataforma-api-datos/',
  '/plataforma/start-trial/': '/intelek-free-trial/',
  '/plataforma/book-demo-form/': '/intelek-book-a-demo/',
  '/plataforma/recommender/': '/soluciones-analisis-ventas/',
  '/soluciones/industry/': '/soluciones-datos-sector-industrial/',
  '/soluciones/retail/': '/soluciones-datos-sector-retail/',
  '/soluciones/insurance/': '/soluciones-datos-sector-seguros/',
  '/soluciones/hospitality/': '/soluciones-datos-sector-hosteleria/',
  '/soluciones/admin/': '/soluciones-datos-sector-administracion/',
  '/soluciones/strategy/': '/soluciones-estrategia-analitica/',
  '/soluciones/machine-learning/': '/soluciones-machine-learning/',
  '/soluciones/business-intelligence/': '/soluciones-business-intelligence/',
  '/soluciones/infrastructure/': '/soluciones-infraestructura-datos/',
  // '/nosotros/blog/': '/blog/',
  // '/nosotros/blog-entry/': '/blog-post/',
  '/nosotros/careers-fira/': '/nosotros/forum-fme/',
  '/otros/aviso-legal.html': '/aviso-legal.html',
  '/otros/terms-and-conditions.html': '/terminos-y-condiciones.html',
  '/otros/politica-privacidad.html': '/politica-privacidad.html',
};

import { default as url_es } from '../../../urlMap_ES';
import { default as url_en } from '../../../urlMap_EN';
import { LANGUAGE } from '../../constants';
import { toggleNavBar } from "../../pages/shared/navbar/toggle-navbar";

if (window.location.pathname.indexOf("/en/") > -1) LANGUAGE = "en";

function changeLanguage(language) {
  const pagePath = window.location.pathname.replace('/en/', '/');
  const originLanguage = window.location.pathname.indexOf('/en/') > -1 ? 'en' : 'es';
  if (originLanguage === language) return;

  let pathId = null;
  let newPath = null;
  let lngStr = '';
  if (originLanguage === 'en') {
    const pathPair = Object.entries(url_en).find((el) => el[1] === pagePath);
    pathId = pathPair ? pathPair[0] : null;
  } else if (originLanguage === 'es') {
    const pathPair = Object.entries(url_es).find((el) => el[1] === pagePath);
    pathId = pathPair ? pathPair[0] : null;
  }

  if (language === 'es') {
    newPath = pathId ? url_es[pathId] : pagePath;
  } else if (language === 'en') {
    newPath = pathId ? url_en[pathId] : pagePath;
    lngStr = '/en';
  }

  const newUrl = window.location.origin + lngStr + newPath + window.location.search;
  window.location.assign(newUrl);
}

//! JULIO: Tengo que revisar al detalle esto de aquí.
if (
  window['__PRERENDER_INJECTED'] && 
  window['__PRERENDER_INJECTED'].render_text
) { } else {
  setTimeout(() => {
    toggleNavBar();
    setFadeObserver();
    setLanguageListeners()
  }, 300);
}

function setLanguageListeners() {
  const langButtonEs = document.getElementById('language-es');
  if (langButtonEs)
    langButtonEs.addEventListener('click', (e) => {
      e.preventDefault();
      changeLanguage('es');
    });
  const langButtonEn = document.getElementById('language-en');
  if (langButtonEn)
    langButtonEn.addEventListener('click', (e) => {
      e.preventDefault();
      changeLanguage('en');
    });
  const footerLangButtonEs = document.getElementById('footer-language-es');
  if (footerLangButtonEs)
    footerLangButtonEs.addEventListener('click', (e) => {
      e.preventDefault();
      changeLanguage('es');
    });

  const footerLangButtonEn = document.getElementById('footer-language-en');
  if (footerLangButtonEn)
    footerLangButtonEn.addEventListener('click', (e) => {
      e.preventDefault();
      changeLanguage('en');
    });
}

export function setFadeObserver() {
  // FADE SECTION
  const fade = document.querySelectorAll('.l_fade');
  const fadeLg = document.querySelectorAll('.l_fade-lg');
  const allFadeElements = [...fade, ...fadeLg];

  // fade callback
  const fadeObserverCallback = (fadeToWatch) => {
    fadeToWatch.forEach((fadeToWatch) => {
      if (fadeToWatch.isIntersecting) {
        fadeToWatch.target.classList.add('fade');
      } else if (fadeToWatch.boundingClientRect.top > 0) {
        fadeToWatch.target.classList.remove('fade');
      }
    });
  };

  const device = getComputedStyle(document.body, '::before').content.replace(/"/g, '');
  const fadeObserverOptions = {
    threshold: device === 'mobile' ? 0.05 : 0.1,
  };

  // fade observer
  const fadeObserver = new IntersectionObserver(fadeObserverCallback, fadeObserverOptions);

  // fade observer on negocios
  allFadeElements.forEach((fade) => {
    fadeObserver.observe(fade);
  });
} //END FADE SECTION

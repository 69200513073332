module.exports = {
  '/': '/',
  '/servicios/expert-guidance/': '/services-data-strategy/',
  '/servicios/data-preparation/': '/services-data-analytics/',
  '/servicios/data-architecture/': '/services-data-architecture/',
  '/servicios/business-intelligence/': '/services-data-visualization/',
  '/servicios/artificial-intelligence/': '/services-artificial-intelligence/',
  '/plataforma/intelek-landing/': '/plataforma-de-gestion-de-datos/',
  '/plataforma/smart-bi/': '/solucion-business-intelligence/',
  '/plataforma/features-analytics/': '/solucion-business-intelligence-funcionalidades/',
  '/plataforma/pricing-analytics/': '/solucion-business-intelligence-pricing/',
  '/plataforma/data-manager/': '/platform-data-management/',
  '/plataforma/governance/': '/platform-data-gobernance/',
  '/plataforma/collector/': '/sistema-de-captura-de-datos/',
  '/plataforma/features-collector/': '/sistema-de-captura-de-datos-funcionalidades/',
  'plataforma/pricing-collector': '/sistema-de-captura-de-datos-precios/',
  '/plataforma/compute/': '/software-de-procesamiento-de-datos/',
  '/plataforma/features-compute/': '/software-de-procesamiento-de-datos-funcionalidades/',
  '/plataforma/pricing-compute/': '/software-de-procesamiento-de-datos-precios/',
  '/plataforma/data-api/': '/platform-data-api/',
  '/plataforma/start-trial/': '/intelek-free-trial/',
  '/plataforma/book-demo-form/': '/intelek-book-a-demo/',
  '/plataforma/recommender/': '/soluciones-de-analisis-de-ventas/',
  '/soluciones/industry/': '/data-solutions-industry/',
  '/soluciones/retail/': '/data-solutions-retail/',
  '/soluciones/insurance/': '/data-solutions-insurance/',
  '/soluciones/hospitality/': '/data-solutions-hospitality/',
  '/soluciones/admin/': '/data-solutions-administration/',
  '/soluciones/strategy/': '/data-solutions-strategy/',
  '/soluciones/machine-learning/': '/data-solutions-machine-learning/',
  '/soluciones/business-intelligence/': '/data-solutions-business-intelligence/',
  '/soluciones/infrastructure/': '/data-solutions-data-infrastructure/',
  // '/nosotros/blog/': '/blog/',
  // '/nosotros/blog-entry/': '/blog-post/',
  '/nosotros/careers-fira/': '/nosotros/forum-fme/',
  '/otros/aviso-legal.html': '/legal-notice.html',
  '/otros/terms-and-conditions.html': '/terms-and-conditions.html',
  '/otros/politica-privacidad.html': '/privacy-policy.html',
};
